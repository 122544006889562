<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div>
          <span>조회기간</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="start_date"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="end_date"
                @change="setEndDate($event)"
              />
            </div>
          </div>
        </div>
        <div class="input_search">
          <input
            type="text"
            placeholder="구매처 검색"
            @input="typingCompany($event)"
            :value="companySearchText"
          />
          <button>
            <i class="fa fa-search"></i>
          </button>
        </div>
        <div class="input_search">
          <input
            type="text"
            placeholder="원자재명 검색"
            @input="typingMaterial($event)"
            :value="searchText"
          />
          <button>
            <i class="fa fa-search"></i>
          </button>
        </div>
        <div class="input_search">
          <input
            type="text"
            placeholder="규격명 검색"
            @input="typingStandard($event)"
            :value="standardText"
          />
          <button>
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>
      <h6>조회수 : {{ return_list.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table
          class="mes_tbl material_return_status"
          :class="{ manage: managementMode }"
        >
          <colgroup>
            <col v-for="(n, index) in managementMode ? 12 : 11" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>반품일</th>
              <th>반품구분</th>
              <th>구매처</th>
              <th>원자재명</th>
              <th>규격</th>
              <th>반품수량(단위)</th>
              <th>단가</th>
              <th>공급가</th>
              <th>부가세</th>
              <th>반환액</th>
              <th>반품사유</th>
              <th v-if="managementMode">반품취소</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in return_list"
              :key="item.id"
              @click="selectRow(index)"
              :class="{ active: selectedIndex == index }"
            >
              <td>
                {{ item.purchase.create_time | formatDateNoHours }}
              </td>
              <td>
                {{
                  findInfoFromId(
                    returnTypes,
                    item.purchase_material.return_type_id,
                  ).name
                }}
              </td>
              <td class="text_left">
                {{ findInfoFromId(companys, item.purchase.company_id).name }}
              </td>
              <td class="text_left">
                {{
                  findInfoFromId(materials, item.purchase_material.material_id)
                    .name
                }}
              </td>
              <td class="text_left">
                {{
                  findInfoFromId(materials, item.purchase_material.material_id)
                    .standard
                }}
              </td>
              <td class="text_right">
                <b>{{ $makeComma(item.purchase_material.quantity) }}</b>
                {{
                  `(${
                    findInfoFromId(units, item.purchase_material.unit_id).name
                  })`
                }}
              </td>
              <td class="text_right">
                &#8361; {{ $makeComma(item.purchase_material.cost) }}
              </td>
              <td class="text_right">
                &#8361; {{ $makeComma(item.purchase_material.supply_value) }}
              </td>
              <td class="text_right">
                &#8361; {{ $makeComma(item.purchase_material.tax) }}
              </td>
              <td class="text_right">
                &#8361; {{ $makeComma(item.purchase_material.total_cost) }}
              </td>
              <td class="text_left">
                {{ item.purchase.detail }}
              </td>
              <td v-if="managementMode" @click="showTwoButtonModal = true">
                <button class="check_btn">
                  취소
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <two-button-modal
      :modal_title="'경고'"
      :modal_content="'정말로 반품 내역을 취소하시겠습니까?'"
      :isModalOpen="showTwoButtonModal"
      @onclose="showTwoButtonModal = false"
      @OnYesClick="
        deleteMaterialReturn();
        showTwoButtonModal = false;
      "
      :yes="my_yes_check"
      :no="my_no_check"
    >
    </two-button-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import FAVORITE_MIXIN from '@/mixins/favorite';
import MODAL_MIXIN from '@/mixins/modal';
import SPINNER_MIXIN from '@/mixins/spinner';
import MATERIAL_PRICE_MIXIN from '@/mixins/material_price';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import { yyyymmdd } from '@/utils/func';

// import { makeComma } from '@/utils/filters';

export default {
  mixins: [
    FETCH_MIXIN,
    MODAL_MIXIN,
    SPINNER_MIXIN,
    MATERIAL_PRICE_MIXIN,
    FAVORITE_MIXIN,
  ],
  components: {
    TwoButtonModal,
  },
  data() {
    return {
      showTwoButtonModal: false,
      my_yes_check: 'yes',
      my_no_check: 'no',
      standardText: '',
    };
  },
  computed: {
    ...mapGetters({
      return_listSrc: 'getReturnListFromMaterialReturnStatus',
      returnTypes: 'getReturnTypes',
      returnOutTypeId: 'getReturnOutTypeId',
      returnOptions: 'getReturnTypesForSelectric',
      companys: 'getCompany',
      materials: 'getMaterial',
      units: 'getUnitCodes',
      selectedIndex: 'getSelectedIndexFromMaterialReturnStatus',
      managementData: 'getManagementDataFromMaterialReturnStatus',
      searchText: 'getSearchTextFromMaterialReturnStatus',
      companySearchText: 'getCompanySearchTextFromMaterialReturnStatus',
      start_date: 'getStartDateFromMaterialReturnStatus',
      end_date: 'getEndDateFromMaterialReturnStatus',
      managementMode: 'getManagementModeFromMaterialReturnPage',
    }),
    return_list() {
      if (this.return_listSrc.length < 1 || this.materials.length < 1) {
        return [];
      } else {
        const Hangul = require('hangul-js');
        return this.lodash
          .clonedeep(this.return_listSrc)
          .filter(
            x =>
              ((this.findInfoFromId(
                this.materials,
                x.purchase_material.material_id,
              ).name.includes(this.searchText) ||
                Hangul.d(
                  this.findInfoFromId(
                    this.materials,
                    x.purchase_material.material_id,
                  ).name,
                  true,
                )
                  .map(x => x[0])
                  .join('')
                  .includes(this.searchText)) &&
                (this.findInfoFromId(
                  this.companys,
                  x.purchase.company_id,
                ).name.includes(this.companySearchText) ||
                  Hangul.d(
                    this.findInfoFromId(this.companys, x.purchase.company_id)
                      .name,
                    true,
                  )
                    .map(x => x[0])
                    .join('')
                    .includes(this.companySearchText)) &&
                x.purchase_material.standard.includes(this.standardText)) ||
              Hangul.d(x.purchase_material.standard, true)
                .map(x => x[0])
                .join('')
                .includes(this.standardText),
          )

          .sort((a, b) => b.purchase.id - a.purchase.id);
      }
    },
  },
  methods: {
    async deleteMaterialReturn() {
      this.showSpinner();
      this.$store
        .dispatch('DELETE_MATERIAL_RETURN', this.managementData.purchase.id)
        .then(response => {
          console.log(response);
          this.FETCH_WITH_PAYLOAD(
            'FETCH_MATERIAL_RETURN_LIST_DATE',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '반품 기록',
          );
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal('오류', '반품 내역을 삭제하는 중 오류발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    typingMaterial(e) {
      this.$store.commit('setSearchTextToMaterialReturnStatus', e.target.value);
      this.$store.commit('setManagementDataToMaterialReturnStatus', null);
      this.$store.commit('setSelectedIndexToMaterialReturnStatus', -1);
    },
    typingStandard(e) {
      this.standardText = e.target.value.trim();
      this.selectRow(-1);
    },
    typingCompany(e) {
      this.$store.commit(
        'setCompanySearchTextToMaterialReturnStatus',
        e.target.value,
      );
      this.$store.commit('setManagementDataToMaterialReturnStatus', null);
      this.$store.commit('setSelectedIndexToMaterialReturnStatus', -1);
    },
    selectRow(index) {
      this.$store.commit('setSelectedIndexToMaterialReturnStatus', index);
      if (index != -1) {
        this.$store.commit(
          'setManagementDataToMaterialReturnStatus',
          this.lodash.clonedeep(this.return_list[index]),
        );
      } else {
        this.$store.commit('setManagementDataToMaterialReturnStatus', null);
      }
    },
    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.end_date})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit(
            'setStartDateToMaterialReturnStatus',
            e.target.value,
          );
          this.FETCH_WITH_PAYLOAD(
            'FETCH_MATERIAL_RETURN_LIST_DATE',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '반품 기록',
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setStartDateToMaterialReturnStatus',
          yyyymmdd(new Date()),
        );
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.start_date})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit(
            'setEndDateToMaterialReturnStatus',
            e.target.value,
          );
          this.FETCH_WITH_PAYLOAD(
            'FETCH_MATERIAL_RETURN_LIST_DATE',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '반품 기록',
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setEndDateToMaterialReturnStatus',
          yyyymmdd(new Date()),
        );
      }
    },
  },
  async created() {
    if (this.returnTypes.length < 1) {
      await this.FETCH('FETCH_MATERIAL_IN_OUT_TYPE');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT');
    }

    if (this.start_date == null) {
      var date = new Date();
      this.$store.commit('setEndDateToMaterialReturnStatus', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 7));
      this.$store.commit('setStartDateToMaterialReturnStatus', yyyymmdd(date2));
    }
    await this.FETCH_WITH_PAYLOAD(
      'FETCH_MATERIAL_RETURN_LIST_DATE',
      {
        start: this.start_date,
        end: this.end_date,
      },
      '반품 기록',
    );
  },
};
</script>

<style lang="scss" scoped></style>
