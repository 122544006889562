<template>
  <div id="contents" class="return" :class="{ return_status: tabIndex == 0 }">
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToMaterialReturnPage'"
          :options="[
            { title: '반품 조회', name: 'modification' },
            { title: '원자재 반품 등록', name: 'new' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <a
            @click="ToggleManagementMode()"
            :class="managementMode ? 'btn_admin on' : 'btn_admin'"
            v-if="isPermissionOn && tabIndex == 0"
            href="#"
            >관리</a
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="tabIndex == 0 ? 'active' : ''">
            <a @click="SetOpenTabIndex(0)">반품 조회</a>
          </li>
          <li :class="tabIndex == 1 ? 'active' : ''" v-if="isPermissionOn">
            <a @click="SetOpenTabIndex(1)">원자재 반품 등록</a>
          </li>
        </ul>
      </div>
      <material-return-status-form
        v-if="tabIndex == 0"
      ></material-return-status-form>
      <material-return-form v-if="tabIndex == 1"></material-return-form>
    </div>
  </div>
</template>

<script>
import closePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import MaterialReturnForm from '@/views/forms/Material/MaterialReturn/MaterialReturnForm.vue';
import { mapGetters, mapMutations } from 'vuex';
import MaterialReturnStatusForm from '@/views/forms/Material/MaterialReturn/MaterialReturnStatusForm.vue';
export default {
  mixins: [closePageMixin, FavoriteMixin],
  components: {
    MaterialReturnForm,
    MaterialReturnStatusForm,
    AsideSelectric,
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromMaterialReturnPage',
      tabIndex: 'getOpenTabIndexFromMaterialReturnPage',
      resource_type: 'getResourceType',
    }),
  },
  methods: {
    ...mapMutations({
      ToggleManagementMode: 'toggleManagementModeToMaterialReturnPage',
      SetOpenTabIndex: 'setOpenTabIndexToMaterialReturnPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitMaterialReturnPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss" scoped></style>
